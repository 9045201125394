$(function() {
  if($(".slider").length == 0) return

  $(".slider").slick({
		infinite: true,
		centerMode: true,
		variableWidth: true,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 600,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 1,
					centerMode: false,
					variableWidth: false,
				}
			}
		]
  })
  $(".slide").show()

  $(".slider").on('breakpoint', function(event, slick, breakpoint) {
    $(".slide").show()
  });
})
