$(function() {
	const bottomFixedLink = $('.p-bottom-fixed-link.sp')

	if(bottomFixedLink.length > 0) {
	  $(window).on('load scroll', function(){
      const scrollHeight = $(document).height()
      const scrollPosition = $(window).height() + $(window).scrollTop()
      const footHeight = $('footer').innerHeight()

	    if($(window).scrollTop() > 240) {
        if(scrollHeight - scrollPosition  <= footHeight) {
	        $('.p-bottom-fixed-link.sp').fadeOut(400)
        } else {
	        $('.p-bottom-fixed-link.sp').fadeIn(400)
        }
	     } else {
	      $('.p-bottom-fixed-link.sp').fadeOut(400)
	     }
	  })
	}
})
