require('src/components/entry_step.js')

$(function() {
  const referral = $(".referral-flow-wrapper")
  const scrollLeft = $(".scroll-left")
  const scrollRight = $(".scroll-right")

  scrollLeft.click(function() {
    referral.animate({ scrollLeft: referral.scrollLeft() - 380 }, 500);
  })

  scrollRight.click(function() {
    referral.animate({ scrollLeft: referral.scrollLeft() + 380 }, 500);
  })

  const scrollJobInquiry = $(".inquiry-btn")
  scrollJobInquiry.click(function() {
    dataLayerPushApply()
  })

  function dataLayerPushApply() {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
      'event': 'apply',
    })
  }
})

jQuery(document).ready(function($) {
	var images = [
	  ['/images/cover_img/01.jpg', 'covar_img_01'],
	  ['/images/cover_img/02.jpg', 'covar_img_02'],
	  ['/images/cover_img/03.jpg', 'covar_img_03'],
	  ['/images/cover_img/04.jpg', 'covar_img_04'],
	  ['/images/cover_img/05.jpg', 'covar_img_05'],
	  ['/images/cover_img/06.jpg', 'covar_img_06'],
	  ['/images/cover_img/07.jpg', 'covar_img_07'],
	  ['/images/cover_img/08.jpg', 'covar_img_08'],
	  ['/images/cover_img/09.jpg', 'covar_img_09'],
	  ['/images/cover_img/10.jpg', 'covar_img_10'],
	  ['/images/cover_img/11.jpg', 'covar_img_11']
	];
	var randImg = images[Math.floor(Math.random() * images.length)];
	$('#covar_img').attr({'src':randImg[0], 'alt':randImg[1]});
  })
