$(function() {
  const prefectureSelect = $("select#job_search_box_prefecture")
  const citySelect = $("select#job_search_box_city")

  if(prefectureSelect.length == 0) return

  if(citySelect.val() == "") {
    setCitySelectOption(prefectureSelect.val())
  }

  prefectureSelect.change(function() {
    setCitySelectOption(prefectureSelect.val())
  })

  function setCitySelectOption(prefectureId) {
    const url = "/api/cities?prefecture_id=" + prefectureId
    $.get(url).done(function(response) {
      citySelect.empty()
      citySelect.append("<option value=''>市区町村を選ぶ</option>")
      $.each(response, function(index, value) {
        const option = "<option value='" + value.code + "'>" + value.name + "</option>"
        citySelect.append(option)
      })
    })
  }
})

$(function() {
  const dateSelectArea = $(".date-select")
  if(dateSelectArea.length == 0) return

  inputToSelected()

  dateSelectArea.on("click", ".prev", function() {
    $(".date-select").load("/api/jobs/search_calender?year_month=" + $(this).data("year-month"), function() {
      inputToSelected()
    })
  })

  dateSelectArea.on("click", ".next", function() {
    $(".date-select").load("/api/jobs/search_calender?year_month=" + $(this).data("year-month"), function() {
      inputToSelected()
    })
  })

  dateSelectArea.on("click", ".day", function() {
    const date = $(this).data("date")
    toggleDay(date)
    selectedToInput()
  })

  dateSelectArea.on("click", ".days-header li", function() {
    const wday = $(this).data("wday")
    const month = $(this).parents(".c-work-date-calender").data("month")
    toggleWeek(month, wday)
    selectedToInput()
  })

  dateSelectArea.on("click", ".calender-header img", function() {
    const month = $(this).parents(".c-work-date-calender").data("month")
    toggleMonth(month)
    selectedToInput()
  })

  function toggleDay(date) {
    const dayListItem = dateSelectArea.find(".day[data-date='"+date+"']")
    dayListItem.toggleClass("selected")
  }

  function toggleWeek(month, wday) {
    const weekCheck = dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .days-header li[data-wday='"+wday+"']")
    weekCheck.toggleClass("selected")
    if(weekCheck.hasClass("selected")) {
      dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .day[data-wday='"+wday+"']").addClass("selected")
    } else {
      dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .day[data-wday='"+wday+"']").removeClass("selected")
    }
  }

  function toggleMonth(month) {
    const monthCheck = dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .calender-header img")
    monthCheck.toggleClass("selected")
    if(monthCheck.hasClass("selected")) {
      monthCheck.attr("src", monthCheck.data("selected-src"))
      dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .day").addClass("selected")
    } else {
      monthCheck.attr("src", monthCheck.data("unselected-src"))
      dateSelectArea.find(".c-work-date-calender[data-month='"+month+"'] .day").removeClass("selected")
    }
  }

  function selectedToInput() {
    const selectedArea = $(".selected-date-inputs")
    const selectedInputs = dateSelectArea.find(".day.selected")
    selectedArea.empty()
    $.each(selectedInputs, function(index, element) {
      const date = $(element).data("date")
      const input = '<input type="hidden" name="job_search_box[work_dates][]" value="'+date+'">'
      selectedArea.append(input)
    })
    updateWeekCheck()
  }

  function inputToSelected() {
    const selectedArea = $(".selected-date-inputs")
    const inputs = selectedArea.find("input")
    $.each(inputs, function(index, input) {
      const date = $(input).val()
      dateSelectArea.find("li[data-date='"+ date +"']").addClass("selected")
    })
    updateWeekCheck()
  }

  function updateWeekCheck() {
    const weekChecks = dateSelectArea.find(".c-work-date-calender .days-header li")
    $.each(weekChecks, function(index, weekCheck) {
      const wday = $(weekCheck).data("wday")
      const daysOfWeek = $(weekCheck).parent().next().find("li[data-wday='"+wday+"']")
      if(daysOfWeek.length === daysOfWeek.filter(".selected").length) {
        $(weekCheck).addClass("selected")
      } else {
        $(weekCheck).removeClass("selected")
      }
    })
  }
})

$(function() {
  const checkAll = $(".check-all")

  checkAll.on("change", "input",function() {
    if($(this).prop("checked")) {
      $(this).parent().parent().next().find("> input").prop("checked", true)
    } else {
      $(this).parent().parent().next().find("> input").prop("checked", false)
    }
  })
})

$(function() {
  const stationInput = $('input#job_search_box_station_input')
  if(stationInput.length == 0) return

  stationInput.autocomplete({
    source: function(request, response) {
      $.ajax({
        url: '/api/stations',
        dataType: "json",
        data: {
          q: request.term
        },
        success: function(data) {
          console.log(data)
          response(data)
        }
      })
    },
    minLength: 1,
    select: function(event, ui) {
      event.preventDefault();
      $('.selected-station input').val(ui.item.value)
      $('.selected-station p').text(ui.item.label)
      stationInput.blur()
      stationInput.addClass('hidden')
      $('.selected-station').removeClass('hidden')
    },
    focus: function(event, ui) {
      event.preventDefault();
      stationInput.val(ui.item.label);
    }
  });

  $('.cancel-station-select').click(function() {
    _cancelStationselect()
  })

  $(".clear-condition").click(function() {
    _cancelStationselect()
  })

  function _cancelStationselect() {
    stationInput.val('')
    $('.selected-station input').val('')
    stationInput.removeClass('hidden')
    $('.selected-station').addClass('hidden')
  }
})

$(function() {
  const clearCondition = $(".clear-condition")
  const searchForm = $(".serach-form form")
  clearCondition.click(function() {
    searchForm.find("textarea, :text, select").val(null)
    searchForm.find(":checked").prop("checked", false)
  })
})
