$(function() {
  const consideringLink = $(".considering-index-link")
  const consideringCount = $(".considering-count")
  consideringLink.click(function() {
    if(consideringCount.text() == "0") {
      alert("求人検討リストは0件です")
      return false
    }
  })
})
