$(function() {
  const sideConsideringJobs = $(".c-consideration-list")

  if(sideConsideringJobs.length < 1) return

  const docH = $(document).innerHeight()
  const winH = $(window).innerHeight()
  const bottomDistance = docH - winH

  const scrolledDistance = $(this).scrollTop()
  const distanceToFix = sideConsideringJobs.offset().top
  const distanceToFadeOut = bottomDistance - 700

  $(document).scroll(function() {
    let scrolledDistance = $(this).scrollTop()

    if(scrolledDistance > distanceToFix) {
      sideConsideringJobs.addClass("fixed")
    } else if(scrolledDistance <= distanceToFix) {
      sideConsideringJobs.removeClass("fixed")
    }
    if(scrolledDistance > distanceToFadeOut) {
      sideConsideringJobs.fadeOut()
    } else if(scrolledDistance < distanceToFadeOut) {
      sideConsideringJobs.fadeIn()
    }
  })
})
