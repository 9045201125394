require('src/components/top_slider.js')
require('src/components/top_search.js')
require('src/components/referral_flow.js')

$(function() {
  const recommendedJobMore = $(".top-recommended-jobs .display-more")
  recommendedJobMore.click(function() {
    $(".top-recommended-jobs").addClass("opened")
  })
})
