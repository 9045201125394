require('src/components/search_box.js')

$(function() {
  const changeCondition = $(".change-conditions .open-search-box")
  const searchBox = $(".c-search-box")
  changeCondition.click(function() {
    searchBox.slideToggle()
    changeCondition.toggleClass("opened")
  })

  const openSearchContents = $(".open-search-contents")
  const searchContents = $(".search-contents")
  openSearchContents.click(function() {
    if(searchContents.hasClass("opened")) {
      searchContents.removeClass("opened")
      openSearchContents.text("もっと見る")
    } else {
      searchContents.addClass("opened")
      openSearchContents.text("閉じる")
    }
  })

  const rankingSecond = $(".popular-area-ranking-lists .second")
  const rankingToggle = $(".popular-area-ranking-toggle")
  rankingToggle.click(function() {
    rankingSecond.toggle()
    rankingToggle.hide()
  })

  const openSummary = $(".summary-toggle")
  openSummary.click(function() {
    $(this).parents(".summary").addClass("opened")
  })
})

$(function() {
  // 検索条件変更ボタンの固定表示
  const fixedBtn = $('.fixed-search-btn')

  if(fixedBtn.length == 0) { return }

  $(window).on('load scroll', function(){
    const modifySearchConditionsBtn = $('.open-search-box')
    const currentFixedBtn = $('.fixed-search-btn')
    const offsetToShowBtn = modifySearchConditionsBtn.offset().top

    if ($(window).scrollTop() > offsetToShowBtn) {
      currentFixedBtn.fadeIn(200);
     } else {
      currentFixedBtn.fadeOut(200);
     }
  })

  fixedBtn.on('click', function() {
    const headerHeightandMargin = 20
    const modifySearchConditionsBtn = $('.open-search-box')
    const offsetToShowBtn = modifySearchConditionsBtn.offset().top

    $("html").animate({ scrollTop: offsetToShowBtn - headerHeightandMargin })

    if(!modifySearchConditionsBtn.hasClass('opened')) {
      modifySearchConditionsBtn.click()
    }
    return false
  })
})
