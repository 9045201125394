$(function() {
  const topSearch = $(".top-search")
  const searchMore = topSearch.find(".search-more")

  searchMore.click(function() {
    $("input[name='authenticity_token']").remove()
    const query = topSearch.find("form").serialize().replace(/job_search_box%5B/g, "")
      .replace(/departments%5D/g, "departments").replace(/work_dates%5D/g, "work_dates")
    const url = $(this).attr("href") + "?" + query
    location.href = url
    return false
  })
})
