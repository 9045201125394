$(function() {
  const header = $("header")
  const headerCover = $("header-cover")
  const openMenuBtn = $("header .open-menu")
  const headerMenu = $("header .menu")

  openMenuBtn.click(function() {
    openMenuBtn.toggleClass("opened")
    headerMenu.toggleClass("opened")
  })

  // ヘッダー内lンテンツリンクの制御
  const openLinksBtn = $("header .open-content-links")
  const links = $(".content-links")

  openLinksBtn.click(function() {
    openLinksBtn.toggleClass("opened")
    links.slideToggle()
    return false
  })

  // 固定ヘッダーの表示制御
  const docH = $(document).innerHeight()
  const winH = $(window).innerHeight()
  const bottomDistance = docH - winH

  const scrolledDistance = $(this).scrollTop()
  const distanceToFix = header.offset().top

  $(document).scroll(function() {
    let scrolledDistance = $(this).scrollTop()

    if(scrolledDistance > 200) {
      header.addClass("fixed")
    } else if(scrolledDistance <= 200) {
      header.removeClass("fixed")
    }
  })
})
