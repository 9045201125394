$(function() {
  const stepWrapper = $('#step-wrapper')
  const stepForm = stepWrapper.find('form')
  const stepAreas = $('.step-area')
  const firstStep = $('#first-step')
  const secondStep = $('#second-step')
  const thirdStep = $('#third-step')
  const fourthStep = $('#fourth-step')
  const completeStep = $('#complete-step')

  if(stepWrapper.length === 0) return

  initialize()

  function initialize() {
    stepWrapper.find('input, select, a').attr('tabindex', '-1')
    $.post('/api/access_logs/'+logType+'/step1')
  }

  // Step moving
  firstStep.find('.next').click(function() {
    if(validateFirst()) {
      stepAreas.animate({ 'margin-left': '-100%' }, 300)
      $.post('/api/access_logs/'+logType+'/step2')
    }
  })

  secondStep.find('.next').click(function() {
    if(validateSecond()) {
      stepAreas.animate({ 'margin-left': '-200%' }, 300)
      $.post('/api/access_logs/'+logType+'/step3')
    }
  })

  secondStep.find('.prev').click(function() {
    stepAreas.animate({ 'margin-left': '0%' }, 300)
    $.post('/api/access_logs/'+logType+'/step1')
  })

  thirdStep.find('.next').click(function() {
    if(validateThird()) {
      stepAreas.animate({ 'margin-left': '-300%' }, 300)
      $.post('/api/access_logs/'+logType+'/step4')
      $(stepWrapper).removeClass("third");
      $(stepWrapper).addClass("fourth");
    }
  })

  thirdStep.find('.prev').click(function() {
    stepAreas.animate({ 'margin-left': '-100%' }, 300)
    $.post('/api/access_logs/'+logType+'/step2')
    $(stepWrapper).removeClass("fourth");
  })

  fourthStep.find('.next').click(function() {
    if(validateFourth() && !$(this).hasClass('disabled')) {
      $(this).addClass('disabled')
      stepForm.submit()
    }
  })

  function submitForm() {
    $.ajax({
      type: 'POST',
      method: 'POST',
      dataType: 'json',
      url: stepForm.attr('action'),
      data: stepForm.serializeArray(),
      crossDomain: true,
    }).done(function(res) {
      stepAreas.animate({ 'margin-left': '-400%' }, 300)
      $.post('/api/access_logs/'+logType+'/complete')
      // pushGtmDataLayerVariable(res)
      if(itemType == "user") {
        pushGtmDataLayerEvent("user_entry")
      } else if(itemType == "job_inquiry") {
        pushGtmDataLayerEvent("job_inquiry")
      }
      history.pushState({}, '', location.pathname + '/complete')
    }).fail(function() {
      alert('エラーが発生しました')
      fourthStep.find('.next').removeClass('disabled')
    })
  }

  function pushGtmDataLayerVariable(res) {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
      'orderNumber': res.order_number.toString(),
    })
  }

  function pushGtmDataLayerEvent(eventName) {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
      'event': eventName,
    })
  }

  fourthStep.find('.prev').click(function() {
    stepAreas.animate({ 'margin-left': '-200%' }, 300)
    $.post('/api/access_logs/'+logType+'/step3')
  })

  // FormValidations
  const errorMessages = $('.error p')

  function validateFirst() {
    errorMessages.hide()

    let result = true
    let errorCount = 0

    const birthYearDiv = $('.birth_year')
    const birthYearVal = birthYearDiv.find('select').val()
    if(!birthYearVal) {
      result = false
      errorCount += 1
      birthYearDiv.next().find('p').show()
    }

    const addressDiv = $('.address')
    const prefectureVal = addressDiv.find('select').first().val()
    const cityVal = addressDiv.find('select').last().val()
    if(!prefectureVal || !cityVal) {
      result = false
      errorCount += 1
      addressDiv.next().find('p').show()
    }

    $(stepWrapper).attr('data-error', errorCount)

    return result
  }

  function validateSecond() {
    errorMessages.hide()

    let result = true
    let errorCount = 0

    const isDoctorDiv = $('.is_pharmacy')
    const isDoctorSelected = isDoctorDiv.find('.radio-btns input:checked')
    if(isDoctorSelected.length == 0) {
      result = false
      errorCount += 1
      isDoctorDiv.next().find('p').show()
    }

    const employmentStatusDiv = $('.employment_status')
    const employmentStatusVal = employmentStatusDiv.find('input:checked').val()
    if(!employmentStatusVal) {
      result = false
      employmentStatusDiv.next().find('p').show()
      errorCount += 1
    }

    $(stepWrapper).attr('data-error', errorCount)

    return result
  }

  function validateThird() {
    errorMessages.hide()

    let result = true
    let errorCount = 0

    const nameDiv = $('.name')
    const nameVal = nameDiv.find('input').val()
    if(nameVal === null || nameVal === '') {
      result = false
      errorCount += 1
      nameDiv.next().find('p').show()
    }

    const kanaDiv = $('.kana')
    const kanaVal = kanaDiv.find('input').val()
    if(kanaVal === null || kanaVal === '') {
      result = false
      errorCount += 1
      kanaDiv.next().find('p.presence').show()
    } else if(!kanaVal.match(/^[ァ-ヶー　]+$/)) {
      result = false
      errorCount += 1
      kanaDiv.next().find('p.format').show()
    }

    $(stepWrapper).attr('data-error', errorCount)

    return result
  }

  function validateFourth() {
    errorMessages.hide()

    let result = true
    let errorCount = 0

    const telDiv = $('.tel')
    const telVal = telDiv.find('input').val()
    if(!telVal) {
      result = false
      errorCount += 1
      telDiv.next().find('p.presence').show()
    } else if(!telVal.match(/^\d{1}[0-9-]{1,12}\d{1}$/)) {
      result = false
      errorCount += 1
      telDiv.next().find('p.format').show()
    }

    const emailDiv = $('.email')
    const emailVal = emailDiv.find('input').val()
    if(!emailVal) {
      result = false
      errorCount += 1
      emailDiv.next().find('p.presence').show()
    } else if(!emailVal.match(/^[a-zA-Z0-9_\#!$%&`'*+\-{|}~^/=?\.]+@[a-zA-Z0-9][a-zA-Z0-9\.-]*[a-zA-Z0-9]+$/)) {
      result = false
      errorCount += 1
      emailDiv.next().find('p.format').show()
    }

    const entryReasonDiv = $('.entry_reasons')
    const entryReasonSelected = entryReasonDiv.find('.checks input:checked')
    if(entryReasonSelected.length == 0) {
      result = false
      errorCount += 1
      entryReasonDiv.next().find('p').show()
    }

    const acceptanceDiv = $('.acceptance')
    const acceptanceVal = acceptanceDiv.find('input:checked')
    if(acceptanceDiv.find('input:checked').length < 1) {
      result = false
      errorCount += 1
      acceptanceDiv.next().find('p').show()
    }

    $(stepWrapper).attr('data-error', errorCount)

    return result
  }

  const prefectureSelect = $('#'+itemType+'_prefecture_code')
  prefectureSelect.change(function() {
    const prefectureId = prefectureSelect.val()
    setCityOptions(prefectureId)
  })

  function setCityOptions(prefectureId) {
    const citySelect = $('#'+itemType+'_city_id')
    const url = '/api/cities?prefecture_id='+prefectureId

    citySelect.empty()
    citySelect.append('<option value>市区町村</option>')
    $.get(url).done(function(result) {
      $.each(result, function(index, city) {
        citySelect.append('<option value="'+city.code+'">'+city.name+'</option>')
      })
    })
  }
})
